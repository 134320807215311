<template>
  <div class="height-100 max_box">
    <!-- <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
      <audio :src="audioSrcList[0]" ref="audio1"></audio>
      <audio :src="audioSrcList[1]" ref="audio2"></audio>
    </div> -->
    <div class="animate__animated animate__fadeIn duration" id="card1">
      <div class="content_box">
        <img class="img1" src="../../assets/img/yanbao1.png" alt="" />
        <div class="info_wrapper">
          <p>{{ extra_param_1 }}</p>
          <div class="yanbao animate__fadeIn none" id="title1">
            <img src="../../assets/img/yanbao2.png" alt="" />
          </div>
          <p id="title2" class="animate__fadeIn none">
            <span>机构观点</span>|<span>个股聚焦</span>
          </p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card2"
    >
      <div class="content_box">
        <div class="top_title">
          <p>
            {{ card_data_1.orgnamedisc[0] }}
            <span>•</span>
            <span>精选研报</span>
          </p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <div
          class="stock"
          v-for="(item, index) in card_data_1.secuabbr"
          :key="index"
        >
          <div class="stock_name">{{ item }}</div>
          <p>{{ card_data_1.shiyi[index] }}；{{ card_data_1.title[index] }}</p>
          <!-- <p>{{ card_data_1.title[index] }}</p> -->
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/xlk.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams, formatToDate, GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template53",
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      extra_param_1: [],

      recordtimeAll: null,
      // device_id:null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      card_data_1: {
        orgnamedisc: [],
        secuabbr: [],
        shiyi: [],
        title: [],
      },
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { extra_param_1, audioSrcList } = this;
      return {
        extra_param_1,
        audioSrcList,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    allReady(val) {
      console.log(val);
      if (val.extra_param_1.length > 0 && val.audioSrcList.length > 0) {
        if (this.ip && this.port) {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordstartPc(this.newIpPort, params)
            .then(({ code }) => {})
            .catch((err) => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
          this.load();
        } else {
          // alert("没获取到ip,port");
          this.load();
        }
      }
    },
  },
  methods: {
    formatToDate,
    getData() {
      let params = {
        model_id: 53,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 53,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.extra_param_1 = data.extra_param_0[0];
            console.log(this.extra_param_1);
            this.card_data_1 = data.chart_data_1[0].data;
            console.log(this.card_data_1);
            setTimeout(() => {
              $("#title1").fadeIn(1000);
            }, 1000);
            setTimeout(() => {
              $("#title2").fadeIn(1000);
            }, 3000);

            if (this.extra_param_1.length == 0 && this.ip) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },

    // 获取旁白
    getAudio() {
      let params = {
        model_id: 53,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 53,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.audioData = data || [];
            this.audioSrcList = data.map((item) => {
              return item.audio_link;
            });
            this.audioTimeList = data.map((item) => {
              return item.scene_duration;
            });
            this.recordtimeAll =
              this.audioTimeList.reduce(function (prev, curr, idx, arr) {
                return prev + curr;
              }) +
              this.audioTimeList.length +
              4 +
              4;

            if (this.audioSrcList.length == 0 && this.ip) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },

    endRecord() {
      let arr = [5, 6 + this.audioTimeList[0]];
      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.recordtimeAll,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    load() {
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 4000);

      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        // this.$refs.audio1.play();
      }, 5000); //间隔

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (5 + this.audioTimeList[0]) * 1000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.endVideo.play();
        // this.$refs.audio2.play();
      }, (6 + this.audioTimeList[0]) * 1000);

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, (this.recordtimeAll + 2) * 1000);
      }
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.max_box {
  font-size: 12px;
  background: url("../../assets/img/bgyanbao2.png") no-repeat center center;
}
#card1 {
  background: url("../../assets/img/bgyanbao.png") no-repeat center center;
  background-size: cover;
}
.card {
  background: none;
  overflow: hidden;
  box-sizing: border-box;
  background: url("../../assets/img/bgyanbao2.png") no-repeat center center;
  background-size: cover;
  .content_box {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 55px 88px;
    font-size: 36px;
    color: #3b2c1a;
  }
}

.logo-box {
  width: 405px;
  opacity: 1;
  bottom: 20%;
}

#card1 {
  .img1 {
    display: block;
    position: absolute;
    top: 217px;
    left: 270px;
  }
  .info_wrapper {
    width: 436px;
    position: absolute;
    top: 486px;
    left: 132px;
    font-size: 36px;
    color: #3b2c1a;
    p:first-of-type {
      font-weight: bold;
      margin-bottom: 48px;
      text-align: center;
      font-family: Source Han Sans CN;
      font-size: 81px;
    }
    .yanbao {
      width: 100%;
      margin-bottom: 32px;
    }
    p:nth-of-type(2) {
      text-align: center;
      font-size: 28px;
      color: #3b2c1a;
      font-weight: 400;
      span:first-of-type {
        margin-right: 30px;
      }
      span:nth-of-type(2) {
        margin-left: 30px;
      }
    }
  }
}

.common_card {
  .top_title {
    width: 100%;
    text-align: center;
    margin-bottom: 58px;
    p:first-of-type {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 82px;
      color: #3b2c1a;
      span:first-of-type {
        margin-left: -3px;
        display: inline-block;
      }
      span:nth-of-type(2) {
        margin-left: 5px;
        display: inline-block;
      }
    }
    p:nth-of-type(2) {
      font-size: 28px;
      color: #3b2c1a;
      font-weight: bold;
    }
  }
  .stock {
    margin-bottom: 20px;
    .stock_name {
      font-weight: bold;
      color: #3b2c1a;
      line-height: 61px;
    }
    p:first-of-type {
      font-size: 28px;
      color: #674a28;
      margin-bottom: 16px;
    }
    p:nth-of-type(2) {
      font-size: 28px;
      color: #674a28;
    }
  }
  .logo-box {
    bottom: 48px;
  }
}
</style>